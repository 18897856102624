import moment from 'moment-timezone'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)

console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
// moment.tz.setDefault(Intl.DateTimeFormat().resolvedOptions().timeZone)
// moment.tz.setDefault('America/New_York')
export default moment

export function getDurationFromStartAndEndTime(
  startTime: number,
  endTime?: number,
  unit: moment.unitOfTime.DurationConstructor = 'seconds'
): moment.Duration {
  // If endTime is not provided, use the current time //
  const durationInSeconds = (endTime || moment().unix()) - startTime
  return moment.duration(durationInSeconds, unit)
}

export const majorTimezones = [
  // American Timezones
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/Anchorage',
  'America/Honolulu',

  // Canadian Timezones
  'Canada/Newfoundland',
  'Canada/Atlantic',
  'Canada/Eastern',
  'Canada/Central',
  'Canada/Mountain',
  'Canada/Pacific',

  // European Timezones
  'Europe/London',
  'Europe/Paris',
  'Europe/Berlin',
  'Europe/Madrid',
  'Europe/Rome',
  'Europe/Athens',
  'Europe/Istanbul',
  'Europe/Moscow',

  // Australian Timezones
  'Australia/Sydney',
  'Australia/Melbourne',
  'Australia/Brisbane',
  'Australia/Adelaide',
  'Australia/Perth',
  'Australia/Darwin',
  'Australia/Hobart'
]
